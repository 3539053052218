














































import { Component, Vue } from 'vue-property-decorator';
import AuthModule from '@/store/modules/AuthModule';
import { IBrowserExtensionConnection } from '@/store/types/IBrowserExtensionConnection';
import { sendViaRelay } from './plasmo';

interface IConnectBrowserExtensionResponse {
  connected: boolean;
}

declare let chrome: any;

@Component
export default class ConnectBrowserExtension extends Vue {
  connected = false;

  async created() {
    const message: IBrowserExtensionConnection = {
      action: 'updateConnectionKey',
      connectionKey: AuthModule.profile!.UserGuid,
      userName: AuthModule.profile!.UserName,
    };

    await this.sendMessageToExtension(
      this.$route.params.extensionid,
      message,
    );
  
  }

  //Cross Browser Messaging
  async sendMessageToExtension(
    extensionId: string,
    message: IBrowserExtensionConnection,
  ): Promise<void> {
    console.log(
      'send message to connect-extension',
      extensionId,
      message,
    );

    try {
      const resp = (await sendViaRelay({
        name: 'connect-extension',
        body: message,
        extensionId: extensionId,
      })) as IConnectBrowserExtensionResponse;

      console.log('Response from background', resp);

      if (resp.connected) {
        this.connected = true;
      } else {
        console.log('Could not connect to extension');
      }
    } catch (error) {
      //todo: handle error
      console.error(`Error sending message: ${error}`);
    }
  }
}
