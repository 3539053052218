import type { PlasmoMessaging } from './plasmotypes';

export const isSameOrigin = (
  event: MessageEvent,
  req: any,
): req is PlasmoMessaging.Request =>
  !req.__internal &&
  event.source === globalThis.window &&
  event.data.name === req.name &&
  (req.relayId === undefined || event.data.relayId === req.relayId);
