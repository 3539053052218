/// The Plasmo Messaging API does not work in node 14 so
/// I had to pull in just the bits I need
/// taken from https://github.com/PlasmoHQ/plasmo/blob/main/api/messaging/src/relay.ts
/// and I also removed the Plasmo Types
import { nanoid } from 'nanoid';
import { isSameOrigin } from './plasmoutils';

export const sendViaRelay = (
  req: any,
  messagePort = globalThis.window,
) =>
  new Promise((resolve, _reject) => {
    const instanceId = nanoid(); // Ensure 'nanoid' is imported or defined in your project

    const messageHandler = (event: any) => {
      if (
        isSameOrigin(event, req) && // Ensure 'isSameOrigin' is defined or implement this check as needed
        event.data.relayed &&
        event.data.instanceId === instanceId
      ) {
        resolve(event.data.body);
        messagePort.removeEventListener('message', messageHandler);
      }
    };

    messagePort.addEventListener('message', messageHandler);

    messagePort.postMessage(
      {
        ...req,
        instanceId,
      },
      req.targetOrigin || '/',
    );
  });
